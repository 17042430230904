/**
 * mockNextUseRouter
 * Mocks the useRouter React hook from Next.js on a test-case by test-case basis
 */
export function mockNextUseRouter({ route, pathname, push }) {
  jest.spyOn(require('next/router'), 'useRouter').mockImplementation(() => ({
    route: route || pathname,
    pathname,
    push,
  }));
}
